/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 105번째 로그에서는 AWS 장애, M1 맥미니, 요기요 매각, NFL DSLR 카메라 중계에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "라이브 커머스"), "\n", React.createElement(_components.h3, null, "맥미니 M1"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/mac-mini/"
  }, "M1 Mac mini - Apple")), "\n"), "\n", React.createElement(_components.h3, null, "AWS 장애(NLB)"), "\n", React.createElement(_components.h3, null, "요기요 매각 결정"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://zdnet.co.kr/view/?no=20201228174022"
  }, "딜리버리히어로 \"공정위 요기요 매각 결정 유감이지만 따르겠다\" - ZDNet")), "\n"), "\n", React.createElement(_components.h3, null, "NFL DSLR 카메라 중계"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.engadget.com/fox-sports-gives-football-a-cinematic-look-with-sony-mirrorless-cameras-200048816.html"
  }, "Fox Sports used a Sony A7R IV to give NFL broadcasts a cinematic look - engadget")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
